import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Question1 from './components/Question1';
import Question2 from './components/Question2';
import Question3 from './components/Question3';
import Question4 from './components/Question4';
import Question5 from './components/Question5';
import './App.css';
import BackToTop from './components/BackToTop';


const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isContentVisible, setContentVisible] = useState(true); // Visible par défaut

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const showContent = () => {
    setContentVisible(true); // Affiche le contenu
    closeSidebar();
  };

  return (
    
    <Router>
      
      <div className="app">
        <button className="menu-button" onClick={openSidebar}>
          &#9776;
        </button>
        <Sidebar 
          isOpen={isSidebarOpen} 
          closeSidebar={closeSidebar} 
          onShowContent={showContent} // Passe showContent en tant que prop
        />
        <div className="content">
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/question1" element={<Question1 />} />
            <Route path="/question2" element={<Question2 />} />
            <Route path="/question3" element={<Question3 />} />
            <Route path="/question4" element={<Question4 />} />
            <Route path="/question5" element={<Question5 />} />
          </Routes>
            <BackToTop />
        </div>
      </div>
    </Router>
  );
};

export default App;
