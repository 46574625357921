import React, { useState } from 'react';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ isOpen, closeSidebar, onShowContent }) => {
  const isMobile = window.innerWidth <= 768; // Vérifie si l'écran est de taille mobile (< 768px)
  const navigate = useNavigate();
  const [selectedQuestion, setSelectedQuestion] = useState(null); // État pour suivre la question sélectionnée

  const handleQuestionClick = (questionNumber) => {
    navigate(`/question${questionNumber}`);
    setSelectedQuestion(questionNumber); // Met à jour la question sélectionnée
    if (isMobile) closeSidebar(); // Ferme la sidebar seulement sur mobile
  };

  const handleScrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) closeSidebar(); // Ferme la sidebar seulement sur mobile
  };

  const handleCommentCaMarcheClick = () => {
    navigate('/');
    onShowContent();
    setSelectedQuestion(null); // Réinitialise la question sélectionnée
    if (isMobile) closeSidebar(); // Ferme la sidebar seulement sur mobile
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      {isMobile && (
        <button className="close-button" onClick={closeSidebar}>&times;</button>
      )}
      <h2>Documentation PipePilot</h2>
      <ul>
        <li>
          <button onClick={handleCommentCaMarcheClick} className={`buttons ${selectedQuestion === null ? 'selected' : ''}`}>Comment ça marche ?</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('profil')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Profil</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('dashboard')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Tableau de bord</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('services')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Services</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('clients')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Clients</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('interventions')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Interventions</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('devis')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Devis</button>
        </li>
        <li>
          <button onClick={() => handleScrollToSection('support')} className={`buttons ${selectedQuestion === null ? '' : ''}`}>Support</button>
        </li>
        <h2>Erreurs potentielles</h2>
        <li>
          <button onClick={() => handleQuestionClick(1)} className={`buttons ${selectedQuestion === 1 ? 'selected' : ''}`}>Alerte : Veuillez compléter votre profil avant de continuer.</button>
        </li>
        <li>
          <button onClick={() => handleQuestionClick(2)} className={`buttons ${selectedQuestion === 2 ? 'selected' : ''}`}>Alerte : Erreur de connexion. Veuillez vérifier vos identifiants ou créer un compte.</button>
        </li>
        <li>
          <button onClick={() => handleQuestionClick(3)} className={`buttons ${selectedQuestion === 3 ? 'selected' : ''}`}>Information : Cet e-mail est déjà utilisé par un autre compte. Veuillez vous connecter.</button>
        </li>
        <li>
          <button onClick={() => handleQuestionClick(4)} className={`buttons ${selectedQuestion === 4 ? 'selected' : ''}`}>Alerte : Votre adresse e-mail n'est pas vérifiée. Veuillez vérifier votre courriel.</button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
