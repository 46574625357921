import React from 'react';
import './Content.css';
import boutonConnexion from '../components/images/boutonConnexion.png';
import pageConnexion from '../components/images/pageConnexion.png';
import pageInscription from '../components/images/pageinscription.png';


const Question1 = () => {
  return (
    <div className="content">
      <h1>Alerte : Erreur de connexion. Veuillez vérifier vos identifiants ou créer un compte. </h1>
      <p>Cette alerte apparaît lorsque nous ne mettez pas une adresse mail reconnu par nos serveurs, ou que vous entrez un mot de passe incorrect pour votre compte. Elle pourrait
        apparaître aussi lorsque vous entrez un compte qui n'existe pas dans nos serveurs.
      </p>
      <br/>
      <p>Pour créer un compte, vous devez procéder comme suit :</p>
      <pre>Étape 1</pre>
      <p>Cliquez sur le bouton <b>Connexion</b> situé en haut à gauche de la page.</p>
      <img src={boutonConnexion} alt="test" className="centered-image2" />
      <p>Vous allez apparaître sur cette page.</p>
      <img src={pageConnexion} alt='test' className='centered-image2' />
      <pre>Étape 2</pre>
      <p>Cliquez sur le bouton  <b>Inscription</b> situé en haut du formulaire.</p>
       <img src={pageInscription} alt="test" className="centered-image" /> 
       <p>Remplissez ce formulaire en mettant vos informations dans les différents champs. Veillez à bien respecter les pré-requis pour le mot de passe.</p>
      <pre>Étape 3</pre>
      <p>Après avoir rempli le formulaire, cliquez sur le bouton <b>S'inscrire</b>.</p>
      <br/>
      <p>Et voila ! Votre compte a bien été créé, vous pouvez profiter des fonctionnalités uniquement si vous avez déjà effectué le paiemet de l'offre.</p>
    </div>
  );
};

export default Question1;
