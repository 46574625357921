import React from 'react';
import './Content.css';
import imgmailconfirmation from '../components/images/imgmailconfirmation.png';


const Question4 = () => {
  return (
    <div className="content">
      <h1>Alerte : Votre adresse e-mail n'est pas vérifiée. Veuillez vérifier votre courriel.</h1>
      <br/>
      <p>Cette alerte apparaît lorsque vous n'avez pas vérifié votre adresse mail après la création de votre compte.</p>
      <p>Après la création de votre compte, vous êtes censé recevoir un mail qui ressemble à ceci : </p>
      <img src={imgmailconfirmation} alt='test' className='centered-image2' />
      <p>Vous cliquez sur le lien et vous serez redirigé vers une page qui confirme que vous avez validé votre adresse mail.</p>
      <br/>

    <p> À présent, vous pouvez accéder à la partie administrateur de votre compte.</p>
    </div>
  );
};

export default Question4;
