import React from 'react';
import './Content.css';
import pageInscription from '../components/images/pageinscription.png';
import pageAdmin from '../components/images/pageadmin.png';
import boutonUserPayment from '../components/images/boutonUserPayment.png';
import ListeTarifs from '../components/images/ListeTarifs.png';
import ongletProfil from '../components/images/infoprofil.png';
import ongletClient from '../components/images/ongletclient.png';
import ongletTableaudeBord from '../components/images/onglettdb.png';
import ongletServices from '../components/images/ongletservices.png';
import ongletInterventions from '../components/images/ongletinterventions.png';
import ongletDevis from '../components/images/ongletdevis.png';
import ongletSupport from '../components/images/ongletsupport.png';
import boutonConnexion from '../components/images/boutonConnexion.png';
import pageConnexion from '../components/images/pageConnexion.png';
import ongletNotedefrais from '../components/images/ongletnotesdefrais.png';
import ongletTaches from '../components/images/onglettaches.png';


const Content = () => {
  return (
      <div className="content">
        <h1>PipePilot - Comment ça marche ?</h1>
        <ul>
          {['Gestion des clients', 'Création des devis en 1 seul clic', 'Facturation facile', 'Service client dédié', 'Intuitif et 100 % compatible mobile 📱'].map((feature) => (
            <li key={feature}>
              {feature}
            </li>
          ))}
        </ul>

        <h2>Présentation de fonctionnalités</h2>
        <p>Vous pouvez trouver la liste des différentes fonctionnalités du site PipePilot ci-dessous.</p>

        <pre className='prenon'>Créez votre compte</pre>
        <p>Vous pouvez créer votre compte directement en cliquant sur ce <a className='link' href='https://pipepilot.wecyde.com' target='_blank'> Lien </a> pour accéder aux différentes fonctionnalités du site. Pour cela, procédez comme suit :</p>

        <div className="section-container">
          <div className="image-content">
            {/* <Parallax y={[-20, 20]} tagOuter="figure"> */}
              <img src={boutonConnexion} alt="Connexion Button" className="centered-image2" />
            {/* </Parallax> */}
          </div>
          <div className="text-content">
            <pre>Étape 1</pre>
            <ul>
          {['Cliquez sur le bouton Connexion situé en haut à gauche de la page.'].map((feature) => (
            <li key={feature}>
              {feature}
            </li>
          ))}
        </ul> 
          </div>
        </div>
        <div className="section-container">
          <div className="image-content">
            <img src={pageConnexion} alt='Login Page' className='centered-image2' />
          </div>
          <div className="text-content">
        <pre>Étape 2</pre>
        <ul>
          {['Cliquez sur le bouton "Inscrivez-vous ici"'].map((feature) => (
            <li key={feature}>
              {feature}
            </li>
          ))}
        </ul>         
        </div>
          
        </div>


        <div className="section-container" >
          <div className="image-content">
            <img src={pageInscription} alt="Inscription Page" className="centered-image2" />
          </div>
          <div className="text-content">
        <pre>Étape 3</pre> 
        <ul>
          {['Remplissez ce formulaire.', 'Cliquez sur le bouton S\'inscrire'].map((feature) => (
            <li key={feature}>
              {feature}
            </li>
          ))}
        </ul> 
            <p>Et voila ! Votre compte a bien été créé, vous pouvez profiter des fonctionnalités uniquement si vous avez déjà effectué le paiement de l'offre.</p>
          </div>
        </div>

        <pre><code>Accédez à la partie administrateur</code></pre>
          <p>Vous n'aurez pas accès directement à la partie administrateur car il faut que vous effectuez un paiement d'abord. Pour cela, procédez comme suit : </p>
        <div className="section-container">
          <div className="image-content">
            <img src={boutonUserPayment} alt="Admin Page" className="centered-image2" />
          </div>
          <div className="text-content">

            <p>1. Cliquez sur l\'icône qui est au milieu, vous allez voir apparaître des informations.</p>
            <p> 2. Cliquez ensuite sur le bouton "Plan tarifaire" pour voir les différents tarifs.</p>
          </div>
        </div>
        <div className="section-container">
          <div className="image-content">
            <img src={ListeTarifs} alt="Admin Page" className="centered-image2" />
          </div>
          <div className="text-content">

            <p>Vous allez voir directement cette page. Choisissez le tarif qui vous convient, effectuez le paiement
               et vous aurez accès aux différentes fonctionnalités de l'administrateur. </p>
          </div>
        </div>
        <pre><code>Découvrir les fonctionnalités de la partie administrateur</code></pre>

        <h2 id="profil"><b>L'onglet "Profil"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletProfil} alt="Profil Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La partie droite de la page contient un formulaire de modification de profil utilisateur avec les champs Prénom, Nom, Adresse, Code Postal, Téléphone, Email, et un bouton "Enregistrer" pour gérer les informations personnelles.</p>
          </div>
        </div>

        <h2 id="dashboard"><b>L'onglet "Tableau de bord"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletTableaudeBord} alt="Dashboard Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page montre un tableau de bord pour un service de devis. Il y a des informations sur le nombre de clients et d'interventions. Il y a aussi un bouton pour créer un nouveau devis.</p>
          </div>
        </div>

        <h2 id="dashboard"><b>L'onglet "Tâches"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletTaches} alt="Dashboard Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page montre des tâches que l'utilisateur peut rajouter à sa guise. Cela lui permettra d'être mieux organisé et situé dans son travail.</p>
          </div>
        </div>

        <h2 id="services"><b>L'onglet "Services"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletServices} alt="Services Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page montre les différents services effectués par intervention. Vous pouvez rajouter des services avec le bouton "Ajouter service" tout en haut de la page.</p>
          </div>
        </div>

        <h2 id="clients"><b>L'onglet "Clients"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletClient} alt="Clients Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page affiche les clients avec lesquels vous avez travaillé. Utilisez le bouton "Ajouter" en haut pour inclure de nouveaux clients. Lors de l'ajout, envoyez automatiquement le mot de passe au client via l'adresse e-mail fournie.</p>
          </div>
        </div>

        <h2 id="interventions"><b>L'onglet "Interventions"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletInterventions} alt="Interventions Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page affiche vos interventions validées avec les détails suivants : service effectué, date, e-mail du client, montant, et heure de l'intervention.</p>
          </div>
        </div>

        <h2 id="interventions"><b>L'onglet "Note de frais"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletNotedefrais} alt="Note de frais Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page affiche vos interventions validées avec les détails suivants : service effectué, date, e-mail du client, montant, et heure de l'intervention.</p>
          </div>
        </div>

        <h2 id="devis"><b>L'onglet "Devis"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletDevis} alt="Devis Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page affiche un formulaire à remplir pour créer un devis pour un client dont le service a été effectué ou est sur le point dêtre effectué.</p>
          </div>
        </div>

        <h2 id="support"><b>L'onglet "Support"</b></h2>
        <div className="section-container" >
          <div className="image-content">
            <img src={ongletSupport} alt="Support Tab" className="centered-image2" />
          </div>
          <div className="text-content">
            <p>La page affiche vos interactions avec le support technique. Vous pouvez poser vos questions via le bouton "Nouvelle question".</p>
          </div>
        </div>

      </div>
  );
};

export default Content;
