import React from 'react';
import './Content.css';
import imgemailreinitialisation from '../components/images/imgemailreinitialisation.png';
import pageConnexion from '../components/images/pageConnexion.png';
import pagemdpoublié from '../components/images/pagemdpoublié.png';
import boutonConnexion from '../components/images/boutonConnexion.png';


const Question1 = () => {
  return (
    <div className="content">
      <h1>Information : Cet e-mail est déjà utilisé par un autre
          compte. Veuillez vous connecter.</h1>
          <br/>
      <p>Cette information s'affiche lorsque vous essayez de créer un compte avec une adresse mail déjà existante. 
        Vous devez vous connecter si vous avez le mot de passe, ou faire une demande de réinitialisation du mot de passe. </p>
        <br/>
        <p>Pour faire une demande de réinitialisation de mot de passe, vous devez procéder comme suit : </p>
      <pre>Étape 1</pre>
      <p>Partez d'abord dans l'onglet <b>"Connexion"</b> situé en haut à droite de la page d'accueil.</p>
      <img src={boutonConnexion} alt="test" className="centered-image2" />      
      <pre>Étape 2</pre>
      <p>Ensuite, cliquez sur le texte  <b>Mot de passe oublié</b> situé en bas du formulaire.</p>
      <img src={pageConnexion} alt="test" className="centered-image2" />
      <pre>Étape 3</pre>
      <p>Vous aurez ce formulaire à remplir qui apparaîtra sur votre écran. </p>
      <img src={pagemdpoublié} alt="test" className="centered-image" />
      <p>Entrez votre adresse mail pour recevoir l'email de réinitialisation de mot de passe. </p>
      <pre>Étape 4</pre>
      <p>Vous recevrez un email qui ressemble à ceci : </p>
      <img src={imgemailreinitialisation} alt="test" className='centered-image2' />
      <p>Vous cliquez sur le lien et vous serez redirigé vers une page pour réinitialiser votre mot de passe.</p>
      <pre>Étape 5</pre>
      <p>Lorsque vous aurez terminé, repartez sur la page de connexion pour vous connecter avec votre nouveau mot de passe.</p>
    </div>
  );
};

export default Question1;
