import React from 'react';
import './Content.css';
import partieProfil from '../components/images/partieprofil.png';
import infoProfil from '../components/images/infoprofil.png';

const Question1 = () => {
  return (
    <div className="content">
      <h1>Alerte : Veuillez complèter votre profil avant de continuer. </h1>
      <p>Cette alerte s'affiche lorsque vous essayer d'aller dans un autre onglet juste après la création de votre compte. Il s'affiche car vous n'avez pas 
        encore rempli les informations pour le profil.
      </p>
      <br/>
      <p>Pour compléter votre profil, vous devez procéder comme suit : </p>
      <pre>Étape 1</pre>
      <p>Partez d'abord dans l'onglet <b>"Profil"</b></p>
      <img src={partieProfil} alt="test" className="centered-image" />
      <pre>Étape 2</pre>
      <p>Ensuite, ajoutez toutes vos informations dans les différents champs de texte.</p>
      <img src={infoProfil} alt="test" className="centered-image2" />
      <pre>Étape 3</pre>
      <p>Appuyez sur le bouton <b>Enregistrer</b> et tout est bon !</p>
      <br/>
      <p>Vous pouvez maintenant défiler dans les autres onglets pour découvrir les autres fonctionnalités de PipePilot.</p>
    </div>
  );
};

export default Question1;